import React, { useEffect, useMemo, useState } from 'react';
import { Image, Modal, Table, Button as AntBtn } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { sendSingleRequest } from '../../apis';
import {
  BreadcrumbComponent,
  Button,
  CaretDownIcon,
  CloseIcon,
  Dropdown,
  Input,
  ModalComponent,
  Paragrapgh,
  Spinner,
  Subtitle,
} from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import right_icon from '../../images/right-icon.png';
import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
import { useDispatch, useSelector } from 'react-redux';
import { loadSpatStorages } from '../../store/farms/farms.actions';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import SpatStorageMobileTable from './SpatStorageMobileTable';
import {
  IFarmResource,
  ILineResource,
  ISpatSeed,
  ISpatSource,
  ISpatStorageResource,
} from '../../entities/farms.entities';
import { formatNumber } from '../../entities/util-functions';
import SpatStorageModal from './SpatStorageModal';
import SpatJourneyModal from '../../components/view-modals/SpatJourneyModal';
import {
  selectFarmsData,
  selectSpatStorages,
} from '../../store/farms/farms.selector';
import { TBusinessType } from '../../entities/general.entities';
import { useLocation } from 'react-router-dom';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import { labelRange } from '../../lib/common.helpers';
import DotsMenu from '../../components/shared/dropdown-menu/DotsMenu';
import './styles.scss';

const columns = (lang: TLang | undefined) => [
  {
    title: 'ID',
    key: 'id',
    render: (x: ISpatStorageResource) => <span>{x.id}</span>,
  },
  {
    title: translate(lang, 'Source'),
    key: 'source',
    render: (x: ISpatStorageResource) => <span>{x.source}</span>,
  },
  {
    title: translate(lang, 'Seed type'),
    key: 'seed_type',
    render: (x: ISpatStorageResource) => <span>{x.seed_type ?? '-'}</span>,
  },
  {
    title: translate(lang, 'Source line'),
    key: 'source_line',
    render: (x: ISpatStorageResource) => (
      <span>
        {x?.source_line
          ? `${x?.source_line.farm.name} - ${x?.source_line.line_name}`
          : `${x?.src_line_name ?? ''}`}
      </span>
    ),
  },
  {
    title: translate(lang, 'Destination Line'),
    key: 'destination_line',
    render: (x: ISpatStorageResource) => (
      <span>
        {x?.destination_line
          ? `${x?.destination_line.farm.name} - ${x?.destination_line.line_name}`
          : `${x?.dst_line_name ?? ''}`}
      </span>
    ),
  },
  {
    title: translate(lang, 'Collection date'),
    key: 'collection_date',
    render: (x: ISpatStorageResource) => (
      <span>{defaultDateFormat(x.collection_date)}</span>
    ),
  },
  {
    title: translate(lang, 'Seeded date'),
    key: 'seeded_date',
    render: (x: ISpatStorageResource) => (
      <span>{defaultDateFormat(x.seeded_date)}</span>
    ),
  },
  {
    title: translate(lang, 'Condition'),
    key: 'condition',
    render: (x: ISpatStorageResource) => <span>{x.condition}</span>,
  },
  {
    title: translate(lang, 'Amount'),
    key: 'weight',
    render: (x: ISpatStorageResource) => (
      <div style={{ whiteSpace: 'nowrap' }}>
        <div>Total: {formatNumber(x.weight)}</div>
        <div>Available {formatNumber(x.available_weight ?? 0)}</div>
        <div>Harvested: {formatNumber(x.harvested_weight ?? 0)}</div>
        <div>
          {`Seeded: ${formatNumber(
            x.seedings?.reduce((p, c) => p + c.spat_amount, 0),
          )}`}
        </div>
      </div>
    ),
  },
  {
    title: translate(lang, 'Size'),
    key: 'size',
    render: (x: ISpatStorageResource) => (
      <span>
        {labelRange(
          x.harvest?.shell_length,
          x.harvest?.shell_length_max ?? undefined,
        )}
      </span>
    ),
  },
  {
    title: translate(lang, 'Stage'),
    key: 'stage',
    render: (x: ISpatStorageResource) => <span>{x.stage}</span>,
  },
];

const seedColumns = (type: TBusinessType, lang: TLang | undefined) => [
  {
    title: translate(lang, 'Farm Name'),
    key: 'farm',
    render: (x: ISpatSeed) => <span>{x.line?.farm.name ?? ''}</span>,
  },
  {
    title: translate(lang, 'Line Name'),
    key: 'line',
    render: (x: ISpatSeed) => <span>{x.line?.line_name ?? ''}</span>,
  },
  {
    title: translate(lang, 'Season'),
    key: 'season',
    render: (x: ISpatSeed) => <span>{x.season_name}</span>,
  },
  {
    title:
      type === 'MUSSEL'
        ? `${translate(lang, 'Line Length')} (m)`
        : type === 'OYSTER'
        ? translate(lang, 'Basket count')
        : `${translate(lang, 'Tank Area')} (m²)`,
    key: 'line_length',
    render: (x: ISpatSeed) => (
      <span>
        {type === 'MUSSEL'
          ? x.line_length
          : type === 'OYSTER'
          ? x.basket_count
          : x.tank_area}
      </span>
    ),
  },
  {
    title: translate(lang, 'Amount'),
    key: 'amount',
    render: (x: ISpatSeed) => <span>{formatNumber(x.spat_amount)}</span>,
  },
  {
    title: translate(lang, 'Date seeded'),
    key: 'planned_date_seed',
    render: (x: ISpatSeed) => (
      <span>{defaultDateFormat(x.planned_date_seed)}</span>
    ),
  },
];

const SpatTrackItem: React.FC<{ spat: ISpatSource; c: boolean }> = ({
  spat,
  c,
}) => {
  const spatStorages = useSelector(selectSpatStorages);
  const curStorage = spatStorages.find(x => x.id === spat.id);

  return (
    <>
      <div className='mr-17 ml-17 white-card'>
        <span>Name: {spat.source}</span>
        <br />
        <span>Weight: {curStorage?.weight}</span>
        <br />
        {curStorage?.source_line && (
          <span>
            {`Harvested from ${curStorage.source_line.farm.name} - ${curStorage.source_line.line_name}`}
          </span>
        )}
      </div>
      {c && (
        <div className='d-flex align-items-center justify-content-center'>
          <Image src={right_icon} />
        </div>
      )}
    </>
  );
};

const SpatTrackRow: React.FC<{ spats: ISpatSource[] }> = ({ spats }) => (
  <div className='d-flex justify-content-between ml-9 mt-9 mb-9'>
    {spats.map((item: any, idx: number) => (
      <SpatTrackItem
        key={idx}
        spat={spats[spats.length - idx - 1]}
        c={idx < spats.length - 1}
      />
    ))}
  </div>
);

const weightLabel = (spat: ISpatStorageResource) =>
  spat.type === 'MUSSEL'
    ? `${spat.available_weight} kg`
    : spat.type === 'OYSTER'
    ? `${spat.available_weight} pcs`
    : `${spat.available_weight} kg`;

const ArchiveModal: React.FC<{
  spatStorage: ISpatStorageResource;
  onClose: () => void;
}> = ({ spatStorage, onClose }) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);

  const archiveClick = async () => {
    setDisabled(true);
    const res = await sendSingleRequest(
      { close_action: true, is_closed: true },
      'PUT',
      `api/farm/spat-storage/${spatStorage.id}`,
      true,
    );
    if (res.status) {
      await dispatch(loadSpatStorages());
      onClose();
    } else {
      alert(translate(lang, res.data?.message ?? 'Server error'));
      setDisabled(false);
    }
  };
  const mortalityClick = async () => {
    const available = spatStorage.available_weight;
    setDisabled(true);
    const res = await sendSingleRequest(
      { close_action: true, loss: available },
      'PUT',
      `api/farm/spat-storage/${spatStorage.id}`,
      true,
    );
    if (res.status) {
      await dispatch(loadSpatStorages());
      onClose();
    } else {
      alert(translate(lang, res.data?.message ?? 'Server error'));
      setDisabled(false);
    }
  };

  return (
    <Modal
      visible={true}
      footer={null}
      onCancel={onClose}
      closable={true}
      closeIcon={<CloseIcon />}
      width={600}
    >
      <div className='wrap'>
        <div className='mt-28'>
          <Subtitle size={4} color='black-1' align='left' fontWeight={600}>
            {`You are about to archive this spat storage, even though it still has ${weightLabel(
              spatStorage,
            )} available. What would you like to do?`}
          </Subtitle>
        </div>
        <div className='mt-18 pl-18 pr-18'>
          {spatStorage.seedings.length > 0 && (
            <Paragrapgh size={2} color='black' fontWeight={400} align='center'>
              {`The spat is still hanging on lines: ${spatStorage.seedings
                .map(x => `${x.line?.farm.name} ${x.line?.line_name}`)
                .join(', ')}`}
            </Paragrapgh>
          )}
          {spatStorage.available_weight &&
            spatStorage.available_weight >= spatStorage.weight && (
              <Paragrapgh
                size={2}
                color='black'
                fontWeight={400}
                align='center'
              >
                {!spatStorage.source_line && !spatStorage.src_line_name
                  ? 'The spat is currently fully harvested and not in the water'
                  : `The spat came from ${
                      spatStorage.source_line
                        ? `${spatStorage.source_line.farm.name} - ${spatStorage.source_line.line_name}`
                        : spatStorage.src_line_name
                    } and is fully available now`}
              </Paragrapgh>
            )}
        </div>
        <div className='mt-32'>
          <Subtitle size={4} color='black-1' align='center' fontWeight={600}>
            {`If the spat difference is because of mortality, please let us know`}
          </Subtitle>
        </div>
        <div className='mt-18 d-flex justify-content-center pr-18 pl-18 mb-18'>
          <AntBtn
            type='default'
            danger={true}
            onClick={onClose}
            disabled={disabled}
          >
            {translate(lang, 'Cancel')}
          </AntBtn>
          <div className='ml-18 mr-18'>
            <AntBtn
              type='primary'
              ghost={true}
              onClick={archiveClick}
              disabled={disabled}
            >
              {translate(lang, 'Archive')}
            </AntBtn>
          </div>
          <AntBtn type='primary' onClick={mortalityClick} disabled={disabled}>
            {'Treat difference as mortality'}
          </AntBtn>
        </div>
      </div>
    </Modal>
  );
};

const typeOptions = [
  { id: '', value: '', label: 'All' },
  { id: 'MUSSEL', value: 'MUSSEL', label: 'Mussel' },
  { id: 'OYSTER', value: 'OYSTER', label: 'Oyster' },
  { id: 'SEAWEED', value: 'SEAWEED', label: 'Seaweed' },
];

const SpatStoragesPage = () => {
  const width = useWidth();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);

  const breadcrumbItems = getBreadcrumbMenu('SPAT_STORAGES');
  const { redirectToLine } = useMenuHandler();
  const spatStorages = useSelector(selectSpatStorages);
  const lang = useSelector(selectLang);
  const farms = useSelector(selectFarmsData);

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ISpatStorageResource>();
  const [deleteShow, setDeleteShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [fltType, setFltType] = useState('AVL');
  const [journeyData, setJourneyData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [pageOptions, setPageOptions] = useState({
    current: 1,
    pageSize: 7,
  });
  const [busType, setBusType] = useState<TBusinessType | ''>(
    (query.get('type') as TBusinessType | undefined) ?? '',
  );
  const [filterFarm, setFilterFarm] = useState<IFarmResource>();
  const [filterLine, setFilterLine] = useState<ILineResource>();
  const [archiveData, setArchiveData] = useState<ISpatStorageResource>();

  const onAddClick = () => {
    setSelectedItem(undefined);
    setShowModal(true);
  };
  const showEditModal = (item: ISpatStorageResource) => {
    setShowModal(true);
    setSelectedItem(item);
  };
  const showDeleteModal = (item: ISpatStorageResource) => {
    setSelectedItem(item);
    setDeleteShow(true);
  };
  const deleteSelectedItem = () => {
    if (!selectedItem) return;
    setDisable(true);
    sendSingleRequest(
      {},
      'DELETE',
      `api/farm/spat-storage/${selectedItem.id}`,
      true,
    ).then(res => {
      setDeleteShow(false);
      setDisable(false);
      dispatch(loadSpatStorages());
    });
  };
  const redirectTo = (dataRow: ISpatSeed) => {
    if (dataRow.line) {
      redirectToLine(dataRow.line?.farm.id, dataRow?.line.id);
    }
  };
  const selectFarm = (id_str: string) => {
    const farm = farms.find(f => f.id === Number(id_str));
    setFilterFarm(farm);
    setFilterLine(undefined);
  };
  const selectLine = (id_str: string) => {
    const line = filterFarm?.lines.find(l => l.id === Number(id_str));
    setFilterLine(line);
  };
  const tableColumns = [
    ...columns(lang),
    {
      title: 'More',
      key: 'more',
      align: 'right',
      render: (x: ISpatStorageResource) => {
        let menus = [
          { label: translate(lang, 'Edit'), onClick: () => showEditModal(x) },
          {
            label: translate(lang, 'Delete'),
            onClick: () => showDeleteModal(x),
          },
        ];
        if (x.available_weight && x.available_weight > 0) {
          menus.push({
            label: translate(lang, 'Archive'),
            onClick: () => setArchiveData(x),
          });
        }
        return <DotsMenu items={menus} />;
      },
    },
  ] as ColumnsType<ISpatStorageResource>;

  const filteredSpats = useMemo(() => {
    let result =
      fltType === 'ACV'
        ? spatStorages.filter(
            x =>
              (x.available_weight ?? 0) <= 0 &&
              (!x.harvest || x.harvest.is_catch_spat === true),
          )
        : spatStorages.filter(x => (x.available_weight ?? 0) > 0);
    if (busType.length > 0) {
      result = result.filter(x => x.type === busType);
    }
    if (filterFarm) {
      result = result.filter(x => x.source_line?.farm.id === filterFarm.id);
    }
    if (filterLine) {
      result = result.filter(x => x.source_line?.id === filterLine.id);
    }
    return result;
  }, [fltType, spatStorages, busType, filterFarm, filterLine]);

  const openJourney = async (id: number) => {
    const spatStorage = spatStorages.find(x => x.id === id);
    if (!spatStorage) return;

    setLoading(true);
    const res = await sendSingleRequest(
      {},
      'GET',
      `api/farm/spats-journey/${id}`,
      true,
    );
    setLoading(false);

    if (res.status) {
      setJourneyData(res.data);
    } else {
      alert(translate(lang, res.data?.message ?? 'Server error'));
    }
  };

  useEffect(() => {
    dispatch(loadSpatStorages());
  }, [dispatch]);

  return (
    <div className='h-calc-80 bg-secondary spat-storages-page'>
      {loading && (
        <div className='loader'>
          <Spinner />
        </div>
      )}
      <div className='container pos-relative farms__line'>
        {width > 768 ? (
          <div className='d-flex justify-content-between align-items-center pl-21 pr-15 pt-28 pb-28'>
            <BreadcrumbComponent items={breadcrumbItems} />
            <Button
              color='blue'
              size={1}
              width='middle'
              type='fill'
              onClick={onAddClick}
            >
              {translate(lang, 'Add Spat Storage')}
            </Button>
          </div>
        ) : (
          <div className='w-100'>
            <Button
              className='w-100 mt-17'
              color='blue'
              size={1}
              width='middle'
              type='fill'
              onClick={onAddClick}
            >
              {translate(lang, 'Add Spat Storage')}
            </Button>
          </div>
        )}
        <div className='farms__line-content'>
          <div className='farms__main'>
            <div className='mb-17 d-flex align-items-end'>
              <div className='filter-option mr-12'>
                <Dropdown
                  label=''
                  options={typeOptions}
                  value={busType}
                  onChange={v => setBusType(v as TBusinessType)}
                />
              </div>
              <div className='filter-option mr-12'>
                <Dropdown
                  label=''
                  options={[
                    {
                      id: 'AVL',
                      value: 'AVL',
                      label: translate(lang, 'View Available'),
                    },
                    {
                      id: 'ACV',
                      value: 'ACV',
                      label: translate(lang, 'View Archived'),
                    },
                  ]}
                  value={fltType}
                  onChange={v => setFltType(v)}
                />
              </div>
              <div className='filter-option mr-12'>
                <Dropdown
                  label={translate(lang, 'Source farm')}
                  options={farms.map(f => ({
                    id: f.id.toString(),
                    value: f.id.toString(),
                    label: f.name,
                  }))}
                  value={filterFarm?.id.toString()}
                  onChange={selectFarm}
                  allowClear={true}
                  showSearch={true}
                />
              </div>
              {filterFarm && (
                <div className='filter-option'>
                  <Dropdown
                    label={translate(lang, 'Source line')}
                    options={filterFarm.lines.map(l => ({
                      id: l.id.toString(),
                      value: l.id.toString(),
                      label: l.line_name,
                    }))}
                    value={filterLine?.id.toString()}
                    onChange={selectLine}
                    allowClear={true}
                    showSearch={true}
                  />
                </div>
              )}
            </div>
            <div style={{ overflow: 'auto' }} className='w-100 pos-relative'>
              {width > 768 ? (
                <Table
                  rowKey='id'
                  className='table table--isFarm'
                  style={{
                    width: '1200px',
                    minWidth: '100%',
                    maxWidth: '1200px',
                  }}
                  columns={tableColumns}
                  pagination={pageOptions}
                  onChange={po => setPageOptions(po as any)}
                  dataSource={filteredSpats}
                  expandable={{
                    expandedRowRender: d => (
                      <>
                        <Table
                          rowKey='id'
                          className='table table--isFarm is__child'
                          columns={seedColumns(d.type, lang)}
                          pagination={false}
                          dataSource={d.seedings}
                          onRow={dR => ({
                            onClick: redirectTo.bind(null, dR),
                          })}
                        />
                        <SpatTrackRow spats={d.spat_parents ?? []} />
                      </>
                    ),
                    expandIcon: ({ onExpand, record }) => (
                      <div
                        className='pt-20 pb-20'
                        onKeyDown={() => undefined}
                        onClick={event => {
                          event.stopPropagation();
                          onExpand(record, event);
                        }}
                      >
                        <CaretDownIcon />
                      </div>
                    ),
                  }}
                  onRow={(r: ISpatStorageResource) => ({
                    onClick: () => openJourney(r.id),
                  })}
                />
              ) : (
                <SpatStorageMobileTable
                  data={filteredSpats}
                  showEditModal={showEditModal}
                  showDeleteModal={showDeleteModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {!!showModal && (
        <SpatStorageModal
          visible={true}
          data={selectedItem}
          editId={selectedItem?.id}
          onConfirm={() => {
            setShowModal(false);
            setSelectedItem(undefined);
            dispatch(loadSpatStorages());
          }}
          onCancel={() => {
            setSelectedItem(undefined);
            setShowModal(false);
          }}
        />
      )}
      {!!deleteShow && (
        <ModalComponent
          visible={true}
          onCancel={() => setDeleteShow(false)}
          type='delete'
          title={translate(lang, 'Delete')}
          text={translate(lang, 'Are you sure to delete this data?')}
          disabled={disable}
          onConfirm={deleteSelectedItem}
        />
      )}
      {!!journeyData && (
        <SpatJourneyModal
          data={journeyData}
          visible={true}
          onClose={() => setJourneyData(undefined)}
        />
      )}
      {!!archiveData && (
        <ArchiveModal
          spatStorage={archiveData}
          onClose={() => setArchiveData(undefined)}
        />
      )}
    </div>
  );
};

export default SpatStoragesPage;
